import { useEffectOnce } from 'react-use';
import { useBodyClass } from '@toss/react';
import { useRouter } from 'next/router';

import { event, zepAnalytics } from '@zep/analytics';

import {
  LandingAgency,
  LandingApps,
  LandingBusinessProposal,
  LandingCases,
  LandingExperience,
  LandingFAQ,
  LandingGDPR,
  LandingIntro,
  LandingMyMetaverse,
  LandingPremium,
  LandingRecruit,
  LandingTraffic,
  LandingUsedClient,
  LandingWhy,
} from '../../components/service/home';
import { useMotion } from '../../hooks';
import { useLanding } from '../../services';

import S from './Landing.module.scss';

export const LandingPageView = () => {
  const { locale } = useRouter();
  const { data } = useLanding();

  useMotion([locale, data]);
  useBodyClass('use-typography');

  useEffectOnce(() => {
    zepAnalytics.track('enter_landing');
  });

  return (
    <main className={S.main}>
      {data?.LandingIntro && (
        <LandingIntro
          title={data?.LandingIntro.title}
          source={data?.LandingIntro.source}
          buttonText={data?.LandingIntro.buttonText}
          buttonLink={data?.LandingIntro.buttonLink}
          onButtonClick={ctaClickTracker('intro', data?.LandingIntro)}
        />
      )}
      {data?.LandingExperience && (
        <LandingExperience
          title={data?.LandingExperience.title}
          spaces={data?.LandingExperience.spaces}
          buttonText={data?.LandingExperience.buttonText}
          buttonLink={data?.LandingExperience.buttonLink}
        />
      )}
      {data?.LandingTraffic && (
        <LandingTraffic
          title={data?.LandingTraffic.title}
          stats={data?.LandingTraffic.stats}
        />
      )}
      {data?.LandingUsedClient && (
        <LandingUsedClient
          title={data?.LandingUsedClient.title}
          description={data?.LandingUsedClient.description}
          logos={data?.LandingUsedClient.logos}
          buttonText={data?.LandingUsedClient.buttonText}
          buttonLink={data?.LandingUsedClient.buttonLink}
          onButtonClick={ctaClickTracker('useClient', data?.LandingUsedClient)}
        />
      )}
      {data?.LandingCases && (
        <LandingCases
          title={data?.LandingCases.title}
          list={data?.LandingCases.list}
          buttonText={data?.LandingCases.buttonText}
          buttonLink={data?.LandingCases.buttonLink}
        />
      )}
      {data?.LandingWhy && (
        <LandingWhy
          title={data?.LandingWhy.title}
          list={data?.LandingWhy.list}
          description={data?.LandingWhy.description}
        />
      )}
      {data?.LandingMyMetaverse && (
        <LandingMyMetaverse
          title={data?.LandingMyMetaverse.title}
          list={data?.LandingMyMetaverse.list}
          onItemLinkClick={item => {
            if (item.id === 1) {
              zepAnalytics.track('landing_create_space_template');
            }
            if (item.id === 2) {
              zepAnalytics.track('landing_create_space_editor');
            }
          }}
        />
      )}
      {data?.LandingAgency && (
        <LandingAgency
          title={data?.LandingAgency.title}
          description={data?.LandingAgency.description}
          buttonText={data?.LandingAgency.buttonText}
          buttonLink={data?.LandingAgency.buttonLink}
          mobileImageSrc={data?.LandingAgency.mobileImageSrc}
          tabletImageSrc={data?.LandingAgency.tabletImageSrc}
          desktopImageSrc={data?.LandingAgency.desktopImageSrc}
          imageAlt={data?.LandingAgency.imageAlt}
        />
      )}
      {data?.LandingBusinessProposal && (
        <LandingBusinessProposal
          title={data?.LandingBusinessProposal.title}
          description={data?.LandingBusinessProposal.description}
          buttonText={data?.LandingBusinessProposal.buttonText}
          buttonLink={data?.LandingBusinessProposal.buttonLink}
        />
      )}
      {data?.LandingPremium && (
        <LandingPremium
          logo={data?.LandingPremium.logo}
          title={data?.LandingPremium.title}
          description={data?.LandingPremium.description}
          features={data?.LandingPremium.features}
          buttonText={data?.LandingPremium.buttonText}
          buttonLink={data?.LandingPremium.buttonLink}
          backgroundColor={data?.LandingPremium.backgroundColor}
          onButtonClick={ctaClickTracker('premium', data?.LandingPremium)}
        />
      )}
      {data?.LandingApps && (
        <LandingApps
          title={data?.LandingApps.title}
          description={data?.LandingApps.description}
          appLink={data?.LandingApps.appLink}
          mobileImageSrc={data?.LandingApps.mobileImageSrc}
          desktopImageSrc={data?.LandingApps.desktopImageSrc}
        />
      )}
      {data?.LandingFAQ && (
        <LandingFAQ
          title={data?.LandingFAQ.title}
          list={data?.LandingFAQ.list}
          buttonText={data?.LandingFAQ.buttonText}
          buttonLink={data?.LandingFAQ.buttonLink}
        />
      )}
      {data?.LandingGDPR && <LandingGDPR data={data?.LandingGDPR} />}

      {data?.LandingRecruit && (
        <LandingRecruit
          description1={data?.LandingRecruit.description1}
          description2={data?.LandingRecruit.description2}
          buttonTitle={data?.LandingRecruit.buttonTitle}
        />
      )}
    </main>
  );
};

function ctaClickTracker(
  sectionName: string,
  {
    buttonLink,
    buttonText,
  }: {
    buttonLink: string;
    buttonText: string;
  },
) {
  return () => {
    event('conversion', {
      send_to: 'AW-11409990767/7V7aCLiWnocZEO_I2sAq',
      event_Code: `landing_cta_${sectionName}`,
      button_text: buttonText,
      button_link: buttonLink,
    });
    zepAnalytics.track('landing_cta_click', {
      section_name: sectionName,
      button_link: buttonLink,
      button_text: buttonText,
    });
  };
}
